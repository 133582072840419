import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, {
  deleteData,
  fetchData,
  putData,
  postData,
} from "utils/functions/service";
import {
  Mobility,
  Family,
  Gender,
  UserStatus,
  UserStatusClose,
  UserStatusActif,
  UserStatusPause,
} from "utils/functions/enums";
import Cookies from "js-cookie";
import { buttonUpdate, inputField } from "utils/functions/themes";
import isAdmin from "utils/functions/isAdmin";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Spinner from "utils/spinner/Spinner";
import DeleteButton from "utils/DeleteButton";
import { SearchFunders } from "entity/admin/funder/SearchFunders";

function UserDetail({ id }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [mode] = useState(id ? "update" : "create");
  const [agencies, setAgencies] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [funders, setFunders] = useState<
    Array<{
      id: string;
      name: string;
      people_surname: string;
      people_name: string;
      email: string;
      phone: string;
    }>
  >([]);
  const [selectedFunders, setSelectedFunders] = useState<
    Array<{
      funder_id: string;
    }>
  >([]);
  const [user, setUser] = useState({
    name: "",
    surname: "",
    num_getrouve: 0,
    birth: "",
    agency: "",
    institute: 0,
    gender: "",
    family: "",
    mobility: "",
    status: "",
    email: "",
    address: "",
    phone: "",
    date_start: "",
    date_end: "",
    closeStatus: "",
    actifStatus: "",
    pauseStatus: "",
    isPoec: false,
    isPoei: false,
    isGroupe: false,
    isRSA: false,
    comment: "",
    employee: { id: 0 },
  });
  const [closeStatusVisible, setCloseStatusVisible] = useState(false);
  const [actifStatusVisible, setActifStatusVisible] = useState(true);
  const [pauseStatusVisible, setPauseStatusVisible] = useState(false);

  const [update, setUpdate] = useState("");
  const [error, setError] = useState({
    name: { text: "un nom", value: "" },
    surname: { text: "un prénom", value: "" },
    num_getrouve: { text: "un numéro", value: "" },
    date_end: { text: "une date de départ", value: "" },
  });
  const mobilityOptions = Object.values(Mobility);
  const familyOptions = Object.values(Family);
  const genderOptions = Object.values(Gender);
  const statusOptions = Object.values(UserStatus);
  const closeOptions = Object.values(UserStatusClose);
  const actifOptions = Object.values(UserStatusActif);
  const pauseOptions = Object.values(UserStatusPause);

  const roles = Cookies.get("roles");
  const employee = Cookies.get("user");
  useDocumentTitle(
    `${
      mode === "update"
        ? user.surname + " " + user.name + " - Modification"
        : "Nouveau bénéficiaire"
    }`
  );

  useEffect(() => {
    async function fetchOther() {
      setAgencies(await fetchData(`/agencies/`, "admin"));
      setInstitutes(await fetchData(`/institutes/`, "admin"));
      setFunders(await fetchData(`/funders/`, "admin"));
    }
    async function fetch() {
      try {
        setUser(await fetchData(`/users/${id}`, roles));
        setSelectedFunders(await fetchData(`/funders/users/${id}`, roles));
      } catch (error) {
        navigate("/users");
      }
    }
    if (mode === "update") fetch();
    fetchOther();
  }, [mode, id, navigate, roles]);

  useEffect(() => {
    setCloseStatusVisible(user.status == UserStatus.KOOSHII_END);
    setPauseStatusVisible(user.status == UserStatus.KOOSHII_PAUSE);
    if (user.status) {
      setActifStatusVisible(user.status == UserStatus.KOOSHII_ACTIVE);
    }
  }, [user]);

  const requiredFields = {
    name: "un nom",
    surname: "un prénom",
    num_getrouve: "un numéro",
    date_end: "une date de départ",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const funderIds = selectedFunders.map((funder) => funder.funder_id);

    let formIsValid = true;
    let newErrors = {
      name: { text: "un nom", value: "" },
      surname: { text: "un prénom", value: "" },
      num_getrouve: { text: "un numéro", value: "" },
      date_end: { text: "une date de départ", value: "" },
    };

    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (fieldName === "date_end" && user.status === "Actif") return;
      if (!user[fieldName]) {
        formIsValid = false;
        newErrors[fieldName] = {
          value: `Veuillez saisir ${fieldLabel} s'il vous plaît`,
          text: requiredFields[fieldName],
        };
      }
    });
    setError(newErrors);

    user.agency = user.agency ? user.agency : agencies[0].id;
    user.family = user.family ? user.family : familyOptions[0];
    user.gender = user.gender ? user.gender : genderOptions[0];
    user.mobility = user.mobility ? user.mobility : mobilityOptions[0];
    user.status = user.status ? user.status : statusOptions[0];
    user.institute = user.institute ? user.institute : institutes[0].id;
    user.employee = user.employee ? user.employee : null;
    if (formIsValid) {
      setIsLoading(true);
      try {
        if (mode === "create") {
          user.employee = Cookies.get("userid");
          const resp = await postData(`/users`, user, roles);
          let response = await putData(
            `/funders/users/${resp.data.id}`,
            funderIds,
            roles
          );
          if (response) {
            navigate(`/user/${resp.data.id}`);
          }
        } else {
          await putData(`/users/${id}`, user, roles);
          await putData(`/funders/users/${id}`, funderIds, roles);
          setUpdate("Mise à jour effectuée");
          if (employee !== "Marie Langlois")
            await postData(
              `/email/userUpdate`,
              {
                user: user.surname + " " + user.name,
                employee: employee,
              },
              roles
            );
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setUpdate(
          "Une erreur est survenue, veuillez réessayer ou contacter un administrateur"
        );
      }
    } else {
      setUpdate("");
    }
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (["name", "surname", "num_getrouve"].includes(name)) {
      setError((prevError) => ({
        ...prevError,
        [name]: {
          text: prevError[name].text,
          value:
            value.trim() !== "" && value !== null
              ? ""
              : `Veuillez saisir ${prevError[name].text} s'il vous plaît`,
        },
      }));
    }

    if (
      (name === "status" && value === "Actif") ||
      (name === "date_end" && value !== "" && value !== null)
    ) {
      setError((prevError) => ({
        ...prevError,
        date_end: {
          text: prevError.date_end.text,
          value: "",
        },
      }));
    }
  };

  const handleFunderChange = (event) => {
    const value = event.target.value;
    setSelectedFunders(value.map((funder) => ({ funder_id: funder })));
  };

  const handleDelete = async (closeModal: () => void) => {
    setIsLoading(true);
    try {
      await deleteData(`/users/delete/${id}`, roles);
      closeModal();
      navigate("/users");
    } catch (error) {
      setIsLoading(false);
      setUpdate(
        "Impossible de supprimer le bénéficiaire, veuillez réessayer ou contacter un administrateur"
      );
    }
  };

  const defaultAgency = agencies.length > 0 ? agencies[0].id : "";
  const agency = agencies.find((a) => a.id === user.agency)
    ? user.agency
    : defaultAgency;

  const defaultInstitute = institutes.length > 0 ? institutes[0].id : "";
  const institute = institutes.find((a) => a.id === user.institute)
    ? user.institute
    : defaultInstitute;

  const defaultMobility = mobilityOptions.length > 0 ? mobilityOptions[0] : "";
  const mobility = mobilityOptions.find((a) => a === user.mobility)
    ? user.mobility
    : defaultMobility;

  const defaultFamily = familyOptions.length > 0 ? familyOptions[0] : "";
  const family = familyOptions.find((a) => a === user.family)
    ? user.family
    : defaultFamily;

  const defaultGender = genderOptions.length > 0 ? genderOptions[0] : "";
  const gender = genderOptions.find((a) => a === user.gender)
    ? user.gender
    : defaultGender;

  const defaultStatus = statusOptions.length > 0 ? statusOptions[0] : "";
  const status = statusOptions.find((a) => a === user.status)
    ? user.status
    : defaultStatus;

  const defaultClose = closeOptions.length > 0 ? closeOptions[0] : "";
  const closeStatus = closeOptions.find((a) => a === user.closeStatus)
    ? user.closeStatus
    : defaultClose;

  const defaultActif = actifOptions.length > 0 ? actifOptions[0] : "";
  const actifStatus = actifOptions.find((a) => a === user.actifStatus)
    ? user.actifStatus
    : defaultActif;

  const defaultPause = pauseOptions.length > 0 ? pauseOptions[0] : "";
  const pauseStatus = pauseOptions.find((a) => a === user.pauseStatus)
    ? user.pauseStatus
    : defaultPause;

  return (
    <div
      style={{
        overflow: "hidden",
        overflowY: "auto",
        height: "67vh",
        width: "auto",
      }}
    >
      <ScrollBar>
        {isLoading ? (
          <Spinner forButton={true} />
        ) : (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  rowGap: "2vh",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "900",
                    color: "#203864",
                  }}
                >
                  Informations :
                </span>
                <TextField
                  sx={inputField}
                  label="Nom du bénéficiaire"
                  name="name"
                  value={user.name}
                  onChange={handleChange}
                  error={Boolean(error.name.value)}
                  helperText={error.name.value}
                />
                <TextField
                  sx={inputField}
                  label="Prénom du bénéficiaire"
                  name="surname"
                  value={user.surname}
                  onChange={handleChange}
                  error={Boolean(error.surname.value)}
                  helperText={error.surname.value}
                />
                <TextField
                  sx={inputField}
                  label="Date de naissance"
                  name="birth"
                  type="date"
                  value={
                    user.birth ? moment(user.birth).format("YYYY-MM-DD") : ""
                  }
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={inputField}
                  label="Numéro getrouve"
                  name="num_getrouve"
                  type="number"
                  value={user.num_getrouve}
                  onChange={handleChange}
                  error={Boolean(error.num_getrouve.value)}
                  helperText={error.num_getrouve.value}
                />
                <TextField
                  sx={inputField}
                  select
                  label="Mobilité"
                  name="mobility"
                  value={mobility}
                  onChange={handleChange}
                >
                  {mobilityOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={inputField}
                  select
                  label="Situation familliale"
                  name="family"
                  value={family}
                  onChange={handleChange}
                >
                  {familyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={inputField}
                  select
                  label="Genre"
                  name="gender"
                  value={gender}
                  onChange={handleChange}
                >
                  {genderOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div style={{ borderLeft: "0.1vw solid #203864" }}></div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  rowGap: "2vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    rowGap: "2vh",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "900",
                      color: "#203864",
                    }}
                  >
                    Coordonnées :
                  </span>
                  <TextField
                    sx={inputField}
                    label="Adresse mail"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={inputField}
                    label="Adresse du bénéficiaire"
                    name="address"
                    value={user.address}
                    onChange={handleChange}
                  />
                  <TextField
                    sx={inputField}
                    label="Téléphone"
                    name="phone"
                    type="number"
                    value={user.phone}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ borderTop: "0.1vw solid #203864" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    rowGap: "2vh",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "900",
                      color: "#203864",
                    }}
                  >
                    Rattachement :
                  </span>
                  <TextField
                    sx={inputField}
                    select
                    label="Agence"
                    name="agency"
                    value={agency}
                    onChange={handleChange}
                  >
                    {agencies.map((agency) => (
                      <MenuItem key={agency.id} value={agency.id}>
                        {agency.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    sx={inputField}
                    label="Prescripteur"
                    name="institute"
                    value={institute}
                    onChange={handleChange}
                  >
                    {institutes.map((institute) => (
                      <MenuItem key={institute.id} value={institute.id}>
                        {institute.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <SearchFunders
                    funders={funders}
                    selectedFunders={selectedFunders}
                    handleChange={handleFunderChange}
                  />
                </div>
              </div>
              <div style={{ borderLeft: "0.1vw solid #203864" }}></div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  rowGap: "2vh",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "900",
                    color: "#203864",
                  }}
                >
                  Parcours :
                </span>
                <TextField
                  sx={inputField}
                  label="Date d'arrivé"
                  name="date_start"
                  type="date"
                  value={
                    user.date_start
                      ? moment(user.date_start).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={inputField}
                  label="Date de départ"
                  name="date_end"
                  type="date"
                  value={
                    user.date_end
                      ? moment(user.date_end).format("YYYY-MM-DD")
                      : ""
                  }
                  required={user.status !== "Actif"}
                  onChange={handleChange}
                  error={Boolean(error.date_end.value)}
                  helperText={error.date_end.value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={inputField}
                  select
                  label="Statut"
                  name="status"
                  value={status}
                  onChange={handleChange}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                {closeStatusVisible && (
                  <TextField
                    style={inputField}
                    select
                    label="Raison clôture"
                    name="closeStatus"
                    value={closeStatus}
                    onChange={handleChange}
                  >
                    {closeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {actifStatusVisible && (
                  <TextField
                    style={inputField}
                    select
                    label="Raison actif"
                    name="actifStatus"
                    value={actifStatus}
                    onChange={handleChange}
                  >
                    {actifOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {pauseStatusVisible && (
                  <TextField
                    style={inputField}
                    select
                    label="Raison pause"
                    name="pauseStatus"
                    value={pauseStatus}
                    onChange={handleChange}
                  >
                    {pauseOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {/* Need a boolean with MUI to know if a user has isPoec or not */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isPoec}
                      onChange={handleChange}
                      name="isPoec"
                      color="primary"
                    />
                  }
                  label="POEC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isPoei}
                      onChange={handleChange}
                      name="isPoei"
                      color="primary"
                    />
                  }
                  label="POEI"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isGroupe}
                      onChange={handleChange}
                      name="isGroupe"
                      color="primary"
                    />
                  }
                  label="Groupe"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isRSA}
                      onChange={handleChange}
                      name="isRSA"
                      color="primary"
                    />
                  }
                  label="RSA"
                />
                <TextareaAutosize
                  name="comment"
                  style={inputField}
                  minRows={2}
                  value={user.comment}
                  placeholder="adad"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "5vw",
                marginBottom: "5vh",
              }}
            >
              <Button style={buttonUpdate} type="submit">
                {mode === "create" ? "Créer" : "Enregistrer"}
              </Button>
              {mode === "update" && (
                <DeleteButton handleDelete={handleDelete} />
              )}
            </div>
          </form>
        )}
      </ScrollBar>
    </div>
  );
}

export default UserDetail;
