export enum Gender {
  KOOSHII_MAN = "Homme",
  KOOSHII_WOMAN = "Femme",
  KOOSHII_NONE = "Non précisé",
}

export enum Mobility {
  KOOSHII_NONE = "Non renseignée",
  KOOSHII_LICENCE = "Permis B",
  KOOSHII_VEHICLE = "Véhicule sans permis",
  KOOSHII_MOTORCYCLE = "Scooter / deux roues motorisées",
  KOOSHII_COMMUN = "Transport en commun",
}

export enum Family {
  KOOSHII_NONE = "Non renseignée",
  KOOSHII_SINGLE = "Célibataire",
  KOOSHII_MARRIED = "Marié.e",
  KOOSHII_DIVORCED = "Divorcé.e",
  KOOSHII_WIDOW = "Veuve/veuf",
}

export enum MonitoringStatus {
  KOOSHII_DONE = "Effectué",
  KOOSHII_PROGRAMMED = "Programmé",
  KOOSHII_CANCELED = "Annulé",
}

export enum UserStatus {
  KOOSHII_ACTIVE = "Actif",
  KOOSHII_PAUSE = "Suspendu.e",
  KOOSHII_END = "Clôturé.e",
}

export enum UserStatusClose {
  KOOSHII_NONE = "Non renseignée",
  KOOSHII_CPRO = "Démarrage CPRO",
  KOOSHII_CDDCDI = "Démarrage CDD / CDI",
  KOOSHII_REO_PRESCRIPTEUR = "Réorientation vers le prescripteur initial",
  KOOSHII_REO_NEWSECTEUR = "Réorientation vers un nouveau secteur",
  KOOSHII_REO_NEWFORMATION = "Réorientation vers une formation",
  KOOSHII_IMPOSSIBILITY = "Impossibilité de l'agence à positionner sur un emploi",
  KOOSHII_NOINTERESTING = "Pas intéressé",
  KOOSHII_ALLABSENCE = "Absence répétées tout au long du dispositif d’accompagnement",
}

export enum UserStatusActif {
  KOOSHII_NONE = "Non renseignée",
  KOOSHII_VALORISATION = "Valorisation",
  KOOSHII_KOOSHII = "Parcours Kooshii",
  KOOSHII_POEC = "POEC",
  KOOSHII_POEI = "POEI",
  KOOSHII_GROUPE = "Groupe",
  KOOSHII_CPRO = "Groupes Hors POEC",
}

export enum UserStatusPause {
  KOOSHII_NONE = "Non renseignée",
  KOOSHII_PBMOBILITY = "Problème de disponibilité ou de mobilité",
  KOOSHII_WAITDOCUMENT = "En attente de documents administratifs",
  KOOSHII_WAITGEIQ = "En attente d'une proposition par le GEIQ",
  KOOSHII_WORKALREADY = "En activité temporaire",
  KOOSHII_IMMERSION = "Immersion en stage",
}

export enum Category {
  KOOSHII_FIRST = "Action",
  KOOSHII_SECOND = "Atelier Individuel",
  KOOSHII_THIRD = "Atelier Collectif",
  KOOSHII_FORTH = "Emploi",
}
